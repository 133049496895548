import React from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Button, Select, Spin, Modal } from 'antd';

import NewBindWrapper from './NewBindWrapper';
import Header from './Header';

import intl from 'react-intl-universal';

import styles from './SelectPlatform.less';
import './SelectPlatform.css';
import logo from 'assets/svg/logo.svg';

import { goToWoo } from '../../common/sysconfig';
import BindWrapper from './BindWrapper';
import { updatePlatform } from 'services/login';
import Media from 'react-media';
import MobileHeader from 'routes/Pricing/Mobile/Header';
import { CommonDataCollect } from 'utils/utils';

const { Option } = Select;
@connect(({ guidance, loading, login }) => ({
  guidance,
  loading: loading.models.guidance,
  userInfo: login.userInfo,
  saveLoading: loading.effects['guidance/postGuidance'],
  wooLoading: loading.effects['guidance/goWoo']
}))
class LinkStore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      updatePlatformLoading: false,
      modalVisible:false,
      showHover: 1,
      showBindSPLoading: false,
      showBindWOOLoading: false,
      showBindWIXLoading: false,
      showBindCSVLoading:false
    };

    this.enableWix = true;
  }

  componentDidMount(){
    window.DSERS_CUSTOM_COLLECT({
      action:'select platform',
      user_id: JSON.parse(localStorage.getItem('store'))?.user_id,
      custom_info:[{name:'platform',value:''}],
      store_id:localStorage.getItem('storeId') === 'undefined' ? '' : localStorage.getItem('storeId'),
      platform:'Dsers'
    })
    CommonDataCollect({
      action:'nostore userInfo',
      event_category:'web',
      event_type:'nostoreinfo',
      user_id: this.props?.userInfo?.user_id,
      infostatus:this.props?.userInfo?.status,
      page_url: location.pathname
    })
  }

  platform = 'Shopify';

  // 选择平台
  changePlatform = v => {
    this.platform = v;
  };

  // 点击Access
  handleAccess = async (storetype) => {
    window.dataLayer.push({
      event: 'registerCustomEvent',
      type: 'access',
      position: 'bindPlatform',
      payload: this.platform.toLowerCase()
    });

    window.DSERS_CUSTOM_COLLECT({
      action:'select platform',
      user_id: JSON.parse(localStorage.getItem('store'))?.user_id,
      custom_info:[{name:'platform',value:storetype}],
      store_id:localStorage.getItem('storeId') === 'undefined' ? '' : localStorage.getItem('storeId'),
      platform:'Dsers'
    })

    const platform = this.platform.toLowerCase();
    if (!platform) {
      return;
    }

    try {
      this.setState({
        updatePlatformLoading: true
      });
      const res = await updatePlatform({
        data: {
          action_operation: platform,
          action_type: 1
        }
      });

      if (!(res && res.code === 2010)) {
        return;
      }
    } catch (error) {
      // todo: 上报异常
    }

    this.setState({
      updatePlatformLoading: false
    });

    const { status } = this.props.userInfo;
    const guidance = `${status}`.substring(1, 2);
    const woocommerce = `${status}`.substring(2, 3);


    if (this.platform === 'Shopify') {
      if (`${status}`.length !== 8 || `${status}`.substring(2, 3) == 1) {
        this.props.dispatch({
          type: 'guidance/postGuidance',
          payload: {
            data: {
              status: 10000000
            },
            callback: d => {
              this.selectShopifyCallback();
            }
          }
        });
      } else if (`${status}`.substring(1, 2) == 1) {
        this.props.dispatch({
          type: 'guidance/postGuidance',
          payload: {
            data: {
              status: 11000000
            },
            callback: d => {
              this.selectShopifyCallback();
            }
          }
        });
      } else {
        this.selectShopifyCallback();
      }
      this.setState({
        'showBindSPLoading':false
         })
    } else if (this.platform === 'CSV') {
      //强制跳转bind ae
      this.props.dispatch({
        type: 'guidance/postGuidance',
        payload: {
          data: {
            status: 10010000
          },
          callback: () => {
            this.setState({
              'showBindCSVLoading':false
            })
            // window.location.href = '/app/'; // 首页
            this.props.dispatch(routerRedux.push('/bind/ae'))
          }
        }
      });

    } else if (this.platform === 'Wix' || this.platform === 'eBay') {
      const token = window.localStorage.getItem('dser-pro-authority');
      window.localStorage.setItem('authority_token', token);
      window.localStorage.removeItem('dser-pro-authority');
      const url = `/web/auth-link?platform=${platform}`;
      this.setState({
        'showBindWIXLoading':false
      })
      window.location.replace(url);
    } else {
      // woocommerce
      if (guidance == 1) {
        this.openWoocommerce(11100000);
        return;
      }
      this.openWoocommerce(10100000);
            this.setState({
        'showBindWOOLoading':false
      })
    }
  };

  handleLogout = () => {
    window.dataLayer.push({
      event: 'registerCustomEvent',
      type: 'logout',
      position: 'bindPlatform'
    });

    window.localStorage.removeItem('dser-pro-authority');
    window.localStorage.removeItem('store');
    window.localStorage.removeItem('storeId');
    window.location.href = '/app/login';
  };

  // 存储开通woo
  openWoocommerce = (val) => {
    this.props.dispatch({
      type: 'guidance/postGuidance',
      payload: {
        data: {
          status: val
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            window.location.href = goToWoo
          }
        }
      }
    });
  };




  selectShopifyCallback = () => {
    const { stores, status } = this.props.userInfo;
    // 没店铺绑店铺
    if (stores && stores.length === 0) {
      this.setState({
        modalVisible:true
      })
      window.open("https://apps.shopify.com/dsers","_blank");
      return;
    }

    if (stores.length !== 0) {
      this.props.dispatch({
        type: 'guidance/getPlan',
        payload: {
          callback: d => {
            if (d.code == 2000) {
              // 选套餐
              if (d.data.type == '0') {
                window.location.href = '/app/pricing?a=4';
                return;
              }

              window.location.href = '/app/';
            }
          }
        }
      });
    }
  };

  render() {
    const { updatePlatformLoading, modalVisible } = this.state;
    const { saveLoading, wooLoading, isMobile } = this.props;

    if (!this.props.userInfo) {
      return (
        <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
      );
    }

    return (
      <>
      {
        isMobile ? 
        <div className={styles.container}>
          <MobileHeader />
          <div className={styles.newContainer}>
          <div>
            <p className="platFormLink">Link Your Store</p>
            <p className="platFormLinkStore">
              Please select the platform of the store you want to link.
            </p>
            <a className="logout" onClick={this.handleLogout}>{'Log out >>'}</a>
            <div className={styles.PlatFormcontentBox}>
              <div className={styles.PlatFormcontentBoxItem}>
                <div
                  className="PlatFormcontentBoxItemImg"
                  onClick={() => {
                    this.platform = 'Shopify';
                    this.handleAccess('Shopify');
                  }}
                >
                  <img src={require('../../assets/newBing/shopify.png')}></img>
                </div>
                <p>Link a Shopify store</p>
              </div>
              <div className={styles.PlatFormcontentBoxItem}>
                <div
                  className="PlatFormcontentBoxItemImg"
                  onClick={() => {
                    this.platform = 'Woocommerce';
                    this.handleAccess('Woo');
                  }}
                >
                  <img src={require('../../assets/newBing/woo.png')}></img>
                </div>
                <p>Link a WooCommerce store</p>
              </div>
              
              <div className={styles.PlatFormcontentBoxItem}>
                <div
                  className="PlatFormcontentBoxItemImg"
                  onClick={() => {
                    this.platform = 'Wix';
                    this.handleAccess('Wix');
                  }}
                >
                  <img src={require('../../assets/newBing/Wix.png')}></img>
                </div>
                <p>Link a Wix store</p>
              </div>

              <div className={styles.PlatFormcontentBoxItem}>
                <div
                  className="PlatFormcontentBoxItemImg"
                  onClick={() => {
                    this.platform = 'CSV';
                    this.handleAccess('CSV');
                  }}
                >
                  <img src={require('../../assets/newBing/csv.png')}></img>
                </div>
                <p>Upload your CSV file to link you store</p>
                {/* <p>Upload your CSV file to link your store</p> */}
              </div>
            </div>

            {/* <Select
              defaultValue={this.platform}
              onChange={this.changePlatform}
            >
              <Option value="Shopify">Shopify</Option>
              <Option value="Woocommerce">WooCommerce</Option>
              <Option value="CSV">CSV</Option>
              {this.enableWix ? <Option value="Wix">Wix(Beta)</Option> : null}
              <Option value="eBay">eBay</Option>
            </Select> */}
          </div>

          <div className="PlatFormcontentButton">
            {/* <Button
              type="primary"
              loading={saveLoading || wooLoading || updatePlatformLoading}
              onClick={this.handleAccess}
            >
              {intl.get('public.access')}
            </Button> */}
            {/* <Button type="default" onClick={this.handleLogout}>
              Log out
            </Button> */}
          </div>
        </div>
        </div> 
        :
      <NewBindWrapper bind="platform" isMobile={isMobile}>
        <div className="newContainer">
          <div>
            <p className="platFormLink">Step 1. {intl.get('onBoarding.selectPlatfrom')}</p>
            <p className="platFormLinkStore">
              {intl.get('onBoarding.selectPlatfromTitle')}
            </p>
            <a className="logout" onClick={this.handleLogout}>{intl.get('onBoarding.logout')} >></a>
            <div className="PlatFormcontentBox">
              <div className={`PlatFormcontentBoxItem ${this.state.showHover == 1 ? 'PlatFormcontentBoxItemHover' : null}`} onMouseOver={()=>{this.setState({showHover:1})}} onMouseOut={()=>{this.setState({showHover:0})}}>
                <div
                  className="PlatFormcontentBoxItemImg"
                        onClick={() => {
                          this.setState({
                              'showBindSPLoading':true
                    })
                    this.platform = 'Shopify';
                    this.handleAccess('Shopify');
                  }}
                      >
                        {this.state.showBindSPLoading && <div
                        className="PlatFormcontentBoxItemImgMask">
                   <Spin  style={{ width: '100%', margin: '55px auto' }}/>       
                  </div>}
                  <img src={require('../../assets/newBing/shopify.png')}></img>
                </div>
                <p>{intl.get('onBoarding.linkShopify')}</p>
              </div>
              <div className={`PlatFormcontentBoxItem ${this.state.showHover == 2 ? 'PlatFormcontentBoxItemHover' : null}`} onMouseOver={()=>{this.setState({showHover:2})}} onMouseOut={()=>{this.setState({showHover:0})}}>
                <div
                  className="PlatFormcontentBoxItemImg"
                        onClick={() => {
                          this.setState({
                                'showBindWOOLoading':true
                             })
                    this.platform = 'Woocommerce';
                    this.handleAccess('Woo');
                  }}
                      >
                   {this.state.showBindWOOLoading && <div
                        className="PlatFormcontentBoxItemImgMask">
                   <Spin  style={{ width: '100%', margin: '55px auto' }}/>       
                  </div>}
                  <img src={require('../../assets/newBing/woo.png')}></img>
                </div>
                <p>{intl.get('onBoarding.linkWoo')}</p>
              </div>
              
              <div className={`PlatFormcontentBoxItem ${this.state.showHover == 3 ? 'PlatFormcontentBoxItemHover' : null}`} onMouseOver={()=>{this.setState({showHover:3})}} onMouseOut={()=>{this.setState({showHover:0})}}>
                <div
                  className="PlatFormcontentBoxItemImg"
                        onClick={() => {
                          this.setState({
                    'showBindWIXLoading':true
                             })
                    this.platform = 'Wix';
                    this.handleAccess('Wix');
                  }}
                      >
                {this.state.showBindWIXLoading && <div
                        className="PlatFormcontentBoxItemImgMask">
                   <Spin  style={{ width: '100%', margin: '55px auto' }}/>       
                  </div>}
                  <img src={require('../../assets/newBing/Wix.png')}></img>
                </div>
                <p>{intl.get('onBoarding.linkWix')}</p>
              </div>

              <div className={`PlatFormcontentBoxItem ${this.state.showHover == 4 ? 'PlatFormcontentBoxItemHover' : null}`} onMouseOver={()=>{this.setState({showHover:4})}} onMouseOut={()=>{this.setState({showHover:0})}}>
                <div
                  className="PlatFormcontentBoxItemImg"
                        onClick={() => {
                          this.setState({
                            'showBindCSVLoading':true
                                     })
                    this.platform = 'CSV';
                    this.handleAccess('CSV');
                  }}
                      >
              {this.state.showBindCSVLoading && <div
                        className="PlatFormcontentBoxItemImgMask">
                   <Spin  style={{ width: '100%', margin: '55px auto' }}/>       
                  </div>}
                  <img src={require('../../assets/newBing/csv.png')}></img>
                </div>
                <p>{intl.get('onBoarding.linkCsv')}</p>
                {/* <p>Upload your CSV file to link your store</p> */}
              </div>
            </div>

            {/* <Select
              defaultValue={this.platform}
              onChange={this.changePlatform}
            >
              <Option value="Shopify">Shopify</Option>
              <Option value="Woocommerce">WooCommerce</Option>
              <Option value="CSV">CSV</Option>
              {this.enableWix ? <Option value="Wix">Wix(Beta)</Option> : null}
              <Option value="eBay">eBay</Option>
            </Select> */}
          </div>

          <div className="PlatFormcontentButton">
            {/* <Button
              type="primary"
              loading={saveLoading || wooLoading || updatePlatformLoading}
              onClick={this.handleAccess}
            >
              {intl.get('public.access')}
            </Button> */}
            {/* <Button type="default" onClick={this.handleLogout}>
              Log out
            </Button> */}
          </div>
          <Modal 
            title={intl.get('linkStore.modalTitle')}
            visible={modalVisible}
            closable={false}
            wrapClassName={styles.modalStyle}
            footer={
              <Button
                style={{width:"95px",height:'36px',background:'#FF8F00',borderRadius:'8px',color:'#FFFFFF'}}
                onClick={()=>{
                  window.location.href = '/app/'
                }}
              >
                {intl.get('linkStore.modalButton')}
              </Button>
            }
            >
            <p
             dangerouslySetInnerHTML={{
                __html:
                intl.get('linkStore.modalText')
              }}
            ></p>
          </Modal>
        </div>
      </NewBindWrapper>
        }
      </>
    );
  }
}

export default (props => (
  <Media
    queries={{
      small: '(max-width: 599px)',
    }}
  >
    {matches => (
      <LinkStore
        {...props}
        isMobile={matches.small}
      />
    )}
  </Media>
));